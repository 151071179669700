.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card-header {
  height: 50px;
}

.pointer {
  cursor: pointer;
}

.opacity {
  opacity: 0.3;
}

.scrolling-wrapper {
	overflow-x: auto;
}

.small-input {
  padding: 7px !important;
}

.detail-modal {
  z-index: 9999;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.detail-modal-background {
  background-color: rgb(80, 80, 80, 0.9);
  width: 100%;
  height: 100%;
  z-index: 9999;
  padding: 20px;
}

.detail-modal-content {
  background-color: white;
  max-width: 650px;
  z-index: 9999;
  padding: 30px;
  border-radius: 10px;
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

.MuiInputBase-root .MuiOutlinedInput-root .MuiInputBase-formControl {
  height: 38px !important;
}